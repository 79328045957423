<template>
    <div>
        <select-shop :loadingButton="loadingButton" v-on:getData="getGrabReport"></select-shop>
        <!-- <CButton color="primary" @click="() => { visibleEnd = !visibleEnd }">Toggle right offcanvas</CButton> -->
        <!-- <access-grab></access-grab> -->
        <div v-if="isGrabOrderEnabled">
            <CCard class="shadow-sm">
                <CCardBody>
                    <div class="row">
                        <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
                            <h2 class="card-title mb-3 font-weight-normal">
                                <img src="/img/delivery/grab.png" class="rounded-circle"
                                    style="border-radius: 3px; width: 45px;" /> {{ $t("grabReport")}}
                            </h2>
                        </div>
                        <div class="col-md-2 col-4 col-sm-3 text-right text-success">

                        </div>
                    </div>
                    <CRow>
                        <CCol sm="12" lg="12">
                            <show-date></show-date>
                            <hr />
                            <CRow>
                                <CCol lg="7">
                                    <CRow >
                                        <CCol lg="6">
                                            <div class="card " 
                                                style="box-shadow: #80bb51 0px 0px 0px 2px; color: #80bb51; padding: 1px;">
                                                <div class="card-body  text-center" style="padding: 0px">
                                                    
                                                    <div class="text-muted  text-uppercase font-weight-bold">
                                                        {{ $t("totalRevenue") }}
                                                    </div>
                                                    <div class="h2 "> {{ totalAmountSuccess }} </div>
                                                    <div class="row" >
                                                   
                                                        <div class="col vertical" >
                                                            <div class="col fs-5 fw-semibold">{{totalAmountSuccess}}</div>
                                                            <div class="col text-uppercase text-body-secondary "> {{ $t("success") }}</div>
                                                        </div>
                                                        <div class="vr" ></div>
                                                        <div class="col">
                                                            <div class="col fs-5 fw-semibold">{{totalAmountCancel}}</div>
                                                            <div class="col text-uppercase text-body-secondary ">{{ $t("cancel") }} </div>
                                                        </div>
                                                     </div>
                                                </div>
                                            </div>
                                        </CCol >
                                        <CCol lg="6">
                                            <div class="card"
                                                style="box-shadow: #00a585 0px 0px 0px 2px; color: #00a585 ;padding: 1px;">
                                                <div class="card-body text-center" style="padding: 0px">
                                                    <div class="text-muted  text-uppercase font-weight-bold"> {{ $t("orderTotal") }}</div>
                                                    <div class="h2 "> {{ billTotal }} </div>
                                                    <div class="row" >
                                                        <div class="col vertical" style="box-shadow: #00a585 ">
                                                            <div class="col fs-5 fw-semibold">{{billSuccess}}</div>
                                                            <div class="col text-uppercase text-body-secondary ">{{ $t("orderSuccess") }}</div>
                                                        </div>
                                                        <div class="vr" ></div>
                                                        <div class="col">
                                                            <div class="col fs-5 fw-semibold">{{billCancel}}</div>
                                                            <div class="col text-uppercase text-body-secondary ">{{ $t("orderCancel") }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CCol>
                                        <CCol lg="6" >
                                            <CCard style="box-shadow: 0 0 0 1px #e5e5e5;">
                                                <CCardBody>
                                                <h5>{{ $t("orderStatus") }} </h5>
                                                <CChartDoughnut :datasets="grabDonutCharts.chartData.datasets"
                                                    :labels="grabDonutCharts.chartData.labels"
                                                    :options="grabDonutCharts.chartOptions" class="mt-4"
                                                    style="height: 244px;align-items: center;" />
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                        <CCol lg="6">
                                            <CCard style="box-shadow: 0 0 0 1px #e5e5e5;">
                                                <CCardBody>
                                                    <h5>{{ $t("orderSummary") }}</h5>
                                                    <div class="row mb-2">
                                                        <CButton color="dark" class="col-4" style="font-size: smaller;border-radius: unset;" :class="[{active : isShowStatusSuccess === 'total'}]" variant="outline"   @click="showStatusSummary('total')">
                                                            {{ $t("allOrders") }}
                                                        </CButton>  
                                                        <CButton color="dark" class="col-4" style="font-size: smaller;    border-radius: unset;" :class="[{active : isShowStatusSuccess === 'success'}]" variant="outline"   @click="showStatusSummary('success')">
                                                            {{ $t("successOrders") }}
                                                        </CButton>                                       
                                                        <CButton color="dark" class="col-4"  style="font-size: smaller; border-radius: unset;" :class="[{active : isShowStatusSuccess === 'cancel'}]" variant="outline"     @click="showStatusSummary('cancel')">
                                                            {{ $t("cancelOrders") }}
                                                        </CButton>                                         
                                                    </div>
                                                    <CRow>
                                                        <CCol lg="12" style=" align-items: center;" v-if=" isShowStatusSuccess === 'total'">
                                                            <CChartBar :datasets="deliveryChart.datasets"
                                                                :labels="deliveryChart.chartLabels"
                                                                :options="deliveryChart.chartOptions"
                                                                style="height: 220px;align-items: center;" />
                                                        </CCol>
                                                        <CCol lg="12" style=" align-items: center;" v-if=" isShowStatusSuccess === 'success'">
                                                            <CChartBar :datasets="deliverySuccessChart.datasets"
                                                                :labels="deliverySuccessChart.chartLabels"
                                                                :options="deliverySuccessChart.chartOptions"
                                                                style="height: 220px;align-items: center;" />
                                                        </CCol>
                                                      
                                                        <CCol lg="12" style=" align-items: center;" v-if=" isShowStatusSuccess === 'cancel'">
                                                            <CChartBar :datasets="deliveryChartCancel.datasets"
                                                                :labels="deliveryChart.chartLabels"
                                                                :options="deliveryChart.chartOptions"
                                                                style="height: 220px;align-items: center;" />
                                                        </CCol>
                                                    </CRow>
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                    </CRow>
                                </CCol>
                                <CCol lg="5">
                                    <CCard style="box-shadow: 0 0 0 1px #e5e5e5;">
                                        <CCardBody class="m-3">
                                            <h5> {{ $t("orderCancel") }}</h5>
                                            <CRow>
                                                
                                                <CCol lg="5" style="max-height: 350px;overflow-y:auto;">
                                                    <CCallout v-for="(list, index) in cancelReason" :key="index"
                                                                :style="{
                                                                    borderRadius: '5px',
                                                                    margin: '0.5rem 0',
                                                                    borderLeft: '10px solid ' + getCancelChartColor(index),
                                                                    boxShadow: '0 0.125rem 0.125rem rgba(0, 0, 21, 0.075',
                                                                    // boxShadow: '0 0.02rem 0.01rem ' + getChartColor(index),
                                                                    padding: '5px',
                                                                    backgroundColor: getCategoryBackgroundColor(index)
                                                                }">
                                                        <div>
                                                            <div class="row m-1 text-muted   font-weight-bold"
                                                                style="padding:2px;">
                                                                <div class=" text-center text-dark">
                                                                    {{ index +  1 }}.
                                                                </div>
                                                                <div class="col text-left ">
                                                                    <strong class="text-dark">
                                                                        {{list.reason}}
                                                                    </strong>
                                                                    <div class="m-0 row justify-content-between small ">
                                                                        <p class="m-0"> {{ $t("QTY") }} : {{
                                                                            list.count }}</p>
                                                                       
                                                                    </div>
                                                                    

                                                                </div>

                                                            </div>
                                                        </div>
                                                </CCallout>
                                        
                                                </CCol>
                                                <CCol lg="7" >
                                                    <CChartDoughnut :datasets="cancelreasonList.datasets"
                                                        :labels="cancelreasonList.labels" 
                                                        :options="cancelreasonList.chartOptions"
                                                        style="height: 350px;align-items: center;" />
                                                </CCol>
                                            </CRow>

                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
            <CRow>
                <CCol lg="6">
                    <CCard class="shadow-sm">
                        <CCardBody class="m-3">
                            <h5> {{ $t("bestSellers") }}</h5>
                            <CRow>
                                
                                <CCol lg="5" style="max-height: 270px;overflow-y:auto;">
                                    <CCallout v-for="(list, index) in productBestSeller" :key="index"
                                                :style="{
                                                    borderRadius: '5px',
                                                    margin: '0.5rem 0',
                                                    borderLeft: '10px solid ' + getChartColor(index),
                                                    boxShadow: '0 0.125rem 0.125rem rgba(0, 0, 21, 0.075',
                                                    // boxShadow: '0 0.02rem 0.01rem ' + getChartColor(index),
                                                    padding: '5px',
                                                    backgroundColor: getBackgroundColor(index)
                                                }">
                                        <div>
                                            <div class="row m-1 text-muted  text-uppercase font-weight-bold"
                                                style="padding:2px;">
                                                <div class=" text-center text-dark">
                                                    {{ index +  1 }}.
                                                </div>
                                                <div class="col text-left ">
                                                    <strong class="text-dark">
                                                        {{ $t(list.pluName) }}
                                                    </strong>
                                                    <div class="m-0 row justify-content-between small ">
                                                        <p class="m-0"> {{ $t("QTY") }} : {{
                                                            list.quantity }}</p>
                                                        <p class="m-0"> {{ $t("sales") }} : {{
                                                            list.totalAmount }}</p>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                </CCallout>
                        
                                </CCol>
                                <CCol lg="7" >
                                    <CChartDoughnut :datasets="topProducts.datasets"
                                        :labels="topProducts.labels" :options="topProducts.chartOptions"
                                        style="height: 250px;align-items: center;" />
                                </CCol>
                            </CRow>

                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol lg="6">
                    <CCard class="shadow-sm">
                        <CCardBody class="m-3">
                            <h5>{{ $t("bestSellerstype") }}</h5>
                            <CRow>
                                <CCol lg="7">
                                    <CChartDoughnut :datasets="topCategories.datasets"
                                        :labels="topCategories.labels"
                                        :options="topCategories.chartOptions"
                                        style="height: 250px;align-items: center;" />
                                </CCol>
                                <CCol lg="5" style="max-height: 270px;overflow-y:auto;">
                                    <CCallout v-for="(list, index) in categoryBestSeller" :key="index"
                                                :style="{
                                                    borderRadius: '5px',
                                                    margin: '0.5rem 0',
                                                    borderLeft: '10px solid ' + getChartCategoryColor(index),
                                                    boxShadow: '0 0.125rem 0.125rem rgba(0, 0, 21, 0.075',
                                                    // boxShadow: '0 0.02rem 0.01rem ' + getChartColor(index),
                                                    padding: '5px',
                                                    backgroundColor: getCategoryBackgroundColor(index)
                                                }">
                                            <div>
                                                <div class="row m-1 text-muted  text-uppercase font-weight-bold"
                                                    style="padding:2px;">
                                                    <div class=" text-center text-dark">
                                                        {{ index +  1 }}.
                                                    </div>
                                                    <div class="col text-left ">
                                                        <strong class="text-dark">
                                                            {{ $t(list.name) }}
                                                        </strong>
                                                        <div class="m-0 row justify-content-between small">
                                                            <p class="m-0"> {{ $t("QTY") }} : {{
                                                                list.quantity }}</p>
                                                            <p class="m-0"> {{ $t("sales") }} : {{
                                                                list.totalAmount }}</p>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                    </CCallout>
                                </CCol>
                            </CRow>

                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <CCard   class="shadow-sm " >
                <CCardBody style="overflow-x: scroll;">
                    <h5>{{ $t("hourlySalesReport") }}</h5>
                    <!-- <CChartLine :datasets="chartBar.datasets" :labels="chartBar.labels"
                        :options="chartBar.chartOptions" style="height: 365px; width: 100%;" /> -->
                        <!-- <h5 class="mt-2">{{ $t("heatmapHourlySales") }}</h5> -->
                        <div class="heatmap-container">
                            <v-chart :option="chartOptions" lazy-update class="heatmap-chart"></v-chart>
                        </div>
                </CCardBody>
            </CCard>
            <CCard class="shadow-sm">
                <CCardBody>
                    <CRow>
                        <CCol sm="12" lg="12">

                            <h5 class="mt-2">{{ $t("report.transaction") }}</h5>
                            <CRow class="justify-content-end">
                                <CCol lg="12" md="8" sm="8" col="12">
                                    <div class="sorting-container">
                                        <span class="sorting-label">{{ $t('status') }} : </span>
                                        <div class="sorting-buttons">
                                            <CButton :class="getButtonClass('')" v-on:click="toggleSort('')">
                                            {{ $t('orderTotal') }} 
                                            </CButton>
                                            <CButton :class="getButtonClass('A')" v-on:click="toggleSort('A')">
                                            {{ $t('orderSuccess') }} 
                                            </CButton>
                                            <CButton :class="getButtonClass('V')" v-on:click="toggleSort('V')">
                                            {{ $t('orderCancel') }} 
                                            </CButton>
                                        </div>
                                    </div>
                                </CCol>
                                <!-- <CCol lg="9" md="8" sm="8" col="12">
                                    <div class="sorting-container">
                                        <span class="sorting-label">{{ $t('sortBy') }} : </span>
                                        <div class="sorting-buttons">
                                            <CButton :class="getButtonClass('created_at', 'desc')" v-on:click="toggleSort('created_at', 'desc')">
                                            {{ $t('date') }} 
                                            </CButton>
                                            <CButton :class="getButtonClass('gpTotal','desc')" v-on:click="toggleSort('gpTotal','desc')">
                                            {{ $t('afterGP') }} 
                                            </CButton>
                                            <CButton :class="getButtonClass('grandTotal','desc')" v-on:click="toggleSort('grandTotal','desc')">
                                            {{ $t('grandTotal') }} 
                                            </CButton>
                                        </div>
                                    
                                    </div>
                                </CCol> -->
                                <!-- <CCol lg="3" md="4" sm="4" col="12" class="text-right">
                                    <div class="sorting-container text-right">
                                        <span class="sorting-label">{{ $t('status') }} : </span>
                                         <div class="sorting-buttons">
                                            <select v-model="filterVal" class="custom-select"  @change="getGrabTransaction">
                                                <option value="">{{ $t('orderTotal') }}</option>
                                                <option value="A">{{ $t('orderSuccess') }}</option>
                                                <option value="V">{{ $t('orderCancel') }}</option>
                                            </select>
                                         </div>       
                                    </div>
                                </CCol> -->
                            </CRow>

                            <div style="overflow-x:auto;">
                                <DataTable :items="items" :fields="fields" :loading="loading" hover border clickableRows
                                    style="white-space: nowrap" @row-clicked="toggleDetails"  >
                                    <template #receiptStatus ="{ item, index }">
                                        <td class="py-0 text-center">
                                            {{item.receiptStatus}}
                                        </td>
                                    </template>
                                    <template #details="{ item, index }"
                                        @click="paymentDetail(item.paymentObjectId, item)">
                                        {{ item.receiptItems.orderedOptions }}
                                        <CCollapse :show="details.includes(item)">
                                            <CCardBody>
                                                <h5 class="font-weight-normal text-dark">{{ $t("items") }}</h5>
                                                <div v-show="item.status === $t('sentSuccessfully')" class="mt-4">
                                                    <div class="row mb-2">
                                                        <div class="col-sm-10">
                                                            <h5 class="font-weight-normal">
                                                                {{ $t("orderNumber") }} :
                                                                <b class="text-success">
                                                                    {{ item.orderNumber }}
                                                                </b>
                                                            </h5>
                                                        </div>
                                                        <div class="col-sm-2">
                                                            <h5 class="font-weight-normal text-right text-success">
                                                                {{ item.status }}
                                                            </h5>
                                                        </div>
                                                    </div>

                                                    <table class="table table-borderless p" style="width: 100%">
                                                        <tr style="background-color: rgb(0, 0, 0, 0)">
                                                            <td class="font-weight-normal" style="width: 150px">
                                                                {{ $t("orderDate") }}
                                                            </td>
                                                            <td>
                                                                {{ item.orderedAt }}
                                                            </td>
                                                            <td class="font-weight-normal" style="width: 150px">
                                                                {{ $t("preparing") }}
                                                            </td>
                                                            <td>
                                                                {{ item.acceptedAt }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="font-weight-normal" style="width: 150px">
                                                                {{ $t("delivered") }}
                                                            </td>
                                                            <td>
                                                                {{ item.dueDate }}
                                                            </td>
                                                            <td class="font-weight-normal" style="width: 150px">
                                                                {{ $t("deliveredDate") }}
                                                            </td>
                                                            <td>
                                                                {{ item.shippedAt }}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <table class="table table-striped" :class="item._classes">
                                                    <th v-for="field in fieldsDetail" :key="field.objectId"
                                                        :class="field._classes">
                                                        {{ field.label }}
                                                    </th>
                                                    <tr style="vertical-align: top" v-for="item in itemsDetail(index)"
                                                        :key="item.objectId">
                                                        <td style="vertical-align: top">
                                                            {{ item.receiptNumber }}
                                                        </td>
                                                        <td style="vertical-align: top">
                                                            {{ item.pluCode }}
                                                        </td>
                                                        <td style="vertical-align: top">
                                                            {{ item.pluName }}
                                                            <br />
                                                            <div v-for="option in item.orderedOptions"
                                                                :key="option.objectId">
                                                                <small class="text-muted">
                                                                    <li>
                                                                        {{ option.optionItemName }}
                                                                    </li>
                                                                </small>
                                                            </div>
                                                        </td>
                                                        <td style="vertical-align: top" class="text-center">
                                                            {{ item.quantitySKU }}
                                                        </td>
                                                        <td style="vertical-align: top" class="text-right">
                                                            {{ item.price }}
                                                        </td>
                                                        <td style="vertical-align: top" class="text-right">
                                                            {{ item.discountTotal }}
                                                        </td>
                                                        <td style="vertical-align: top" class="text-right">
                                                            {{ item.totalAmount }}
                                                        </td>
                                                        <td style="vertical-align: top" class="text-right">
                                                            {{ item.netAmount }}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </CCardBody>
                                        </CCollapse>
                                    </template>
                                </DataTable>
                            </div>
                            <pagination :meta_data="meta_data" v-on:next="getGrabTransaction"> </pagination>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
           

        </div>
        <div v-else>
            <access-grab></access-grab>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { use } from "echarts/core";
import { HeatmapChart } from "echarts/charts";
import { GridComponent, TooltipComponent, VisualMapComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";

use([HeatmapChart, GridComponent, TooltipComponent, VisualMapComponent, CanvasRenderer]);

import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import pos from "@/services/local";
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import { CWidgetProgressIcon, CWidgetSimple ,COffcanvas} from "@coreui/vue"
import { CChartDoughnut, CChartLine, CChartBar ,CChartPolarArea} from "@coreui/vue-chartjs";



export default {
    components: {
        VChart,
        Pagination,
        DataTable,
        CWidgetProgressIcon,
        CChartPolarArea,
        CWidgetSimple,
        CChartDoughnut,
        CChartLine,
        CChartBar,
        // heatmap,
        COffcanvas
       
    },
    data() {
        return {
            maxBill: 0,
            datalist: [],
            sortOrder : 'desc',
            sortAttr : 'created_at',
            data: [],
            paymentdata: [],
            details: [],
            meta_data: {
                last_page: null,
                current_page: 1,
                prev_page_url: null,
                items: 0,
                limit: null,
                itemCount: null,
            },
            loadingButton: true,
            loading: true,
            salesChannel: [],
            categories: [],
            product: [],
            summary: {},
            totalRevenue: '0.00',
            totalAmountSuccess: '0.00',
            totalAmountCancel : '0.00',
            orderTotal : '0.00',
            billCancel : 0,
            billSuccess : 0,
            billTotal : 0,
            dataAmount :[],
            ordertype : [],
            deliveryList : [],
            selfPicUpList : [],
            chartLabelsSummary : [],
            deliveryDataSummary : [],
            pickupDataSumary : [],
            orderfrequency : [],
            cancelChartLabelsSummary : [],
            rejectDataSummary : [], 
            cancelDataSummary :[], 
            totalSuccessBills : [],
            summaryOrderCancelData:[],
            productBestSeller: [],
            categoryBestSeller :[],
            isShowStatusSuccess :  'success',
            isGrabOrderEnabled : false,
            filterVal : '',
            cancelReason :[]

        }
    },
    mounted() {


    },
    watch: {
        shopObjectId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getOrderShopSetting()
            }
        }
    },
    computed: {
        ...mapGetters(['shops', 'date', 'users']),    
        startDate() {
            return moment(String(this.date.start)).format("DD MMMM YYYY");
        },
        endDate() {
            return moment(String(this.date.end)).format("DD MMMM YYYY");
        },
        cancelreasonList() {
            const cancelReason = this.cancelReason || [];
            // Sort the cancelReason array by count in descending order
            const sortedCancelReason = cancelReason.sort((a, b) => b.count - a.count);

            const data = [];
            const topLabels = [];
            const topValues = [];

            const count = cancelReason.length ;

            if (count > 0) {
                for (let i = 0; i < count; i++) {
                    const product = sortedCancelReason[i];
                    const object = {
                        count: product.count,
                        reason: product.reason,
                    };
                    data.push(object);
                    topValues.push(product.count);
                    topLabels.push(product.reason);
                }
            } else {
                // No data fallback
                topLabels.push(this.$i18n.t("noData"));
                topValues.push(1); // Placeholder value
            }


            return {
                labels: topLabels,
                datasets: [
                    {
                        backgroundColor: count > 0 
                            ? ["#00b081","#6dbc68","#a9d87d","#e2e986","#a9b252","#dcba5f","#ffc079","#ffc7a0","#ffd18d","#ffd57a","#d6d775","#7dab53","#5ca220"]
                            : ["#ebedef"], // Neutral color for no data
                        data: topValues,
                    },
                ],
                chartOptions: {
                    responsive: true,
                    height: 200,
                    maintainAspectRatio: false,
                    cutoutPercentage: 0, // Doughnut hole size
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                        y: {
                            grid: {
                                display: true,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: count > 0, // Hide default legend

                        },
                       
                        tooltip: {
                            enabled : false,
                            callbacks: {
                                label: function (tooltipItem) {
                                    if (count === 0) return this.$i18n.t("noData");
                                        const value = tooltipItem.raw || 0;
                                        const label = tooltipItem.label || '';
                                        return `${label}: ${value}`;
                                },
                            },
                        },
                    }

                }
            };
        },
        topProducts() {
            const products = this.productBestSeller || [];

            const data = [];
            const topLabels = [];
            const topValues = [];

            const count = products.length >= 5 ? 5 : products.length;

            if (count > 0) {
                for (let i = 0; i < count; i++) {
                    const product = products[i];
                    const object = {
                        quantity: product.quantity,
                        productName: product.pluName,
                    };
                    data.push(object);
                    topValues.push(product.quantity);
                    topLabels.push(product.pluName);
                }
            } else {
                // No data fallback
                topLabels.push(this.$i18n.t("noData"));
                topValues.push(1); // Placeholder value
            }


            return {
                labels: topLabels,
                datasets: [
                    {
                        backgroundColor: count > 0 
                            ? ["#6dbc68", "#cfd06c", "#f7cd72","#d5b57d", "#ffc6a2","#a4a663","#6c9656","#248456","#00705e","#005b47"]
                            : ["#ebedef"], // Neutral color for no data
                        data: topValues,
                    },
                ],
                chartOptions: {
                    responsive: true,
                    height: 200,
                    maintainAspectRatio: false,
                    cutoutPercentage: 0, // Doughnut hole size
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                        y: {
                            grid: {
                                display: true,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: count > 0, // Hide default legend

                        },
                        tooltip: {
                            enabled : false,
                            callbacks: {
                                label: function (tooltipItem) {
                                    if (count === 0) return this.$i18n.t("noData");
                                    const value = tooltipItem.raw || 0;
                                    return `${tooltipItem.label}: ${value}`;
                                },
                            },
                        },
                    }
                },
            };
        },
        topCategories() {
            const categories = this.categoryBestSeller || [];
            const data = [];
            const topLabels = [];
            const topValues = [];

            const count = categories.length >= 5 ? 5 : categories.length;

            if (count > 0) {
                for (let i = 0; i < count; i++) {
                    const category = categories[i];
                    const object = {
                        quantity: category.quantity,
                        categoryName: category.name,
                    };
                    data.push(object);
                    topValues.push(category.quantity);
                    topLabels.push(category.name);
                }
            } else {
                // No data fallback
                topLabels.push(this.$i18n.t("noData"));
                topValues.push(1); // Placeholder value
            }
            // ? ["#55cad7", "#8ae2d2" ,"#8debc6" ,"#9bf1b3", "#b4f69d"]
            // ? ["#b4f69d","#dfe889", "#a8d275" ,"#6dbc68" ,"#0fa562","#9bf1b3" ,"#8debc6","#8ae2d2","#8ae2d2","#55cad7","#00a6ad"]
            // ? ["#bba79c","#ce9876","#d5b57d" ,"#cca663","#8f9c5d" ,"#0fa562","#9bf1b3" ,"#8debc6","#8ae2d2","#8ae2d2","#55cad7","#00a6ad"]
            
            return {
                labels: topLabels,
                datasets: [
                    {
                        backgroundColor: count > 0 
                            ? ["#b4f69d","#dfe889", "#a8d275" ,"#6dbc68" ,"#0fa562","#9bf1b3" ,"#8debc6","#8ae2d2","#8ae2d2","#55cad7","#00a6ad"]
                            : ["#ebedef"], // Neutral color for no data
                        data: topValues,
                    },
                ],
                chartOptions: {
                    responsive: true,
                    height: 200,
                    maintainAspectRatio: false,
                    cutoutPercentage: 0, // Doughnut hole size
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                        y: {
                            grid: {
                                display: true,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: count > 0, // Hide default legend
                        },
                        tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    if (count === 0) return this.$i18n.t("noData");
                                    const value = tooltipItem.raw || 0;
                                    return `${tooltipItem.label}: ${value}`;
                                },
                            },
                        },
                    },
                },
            };
        },
        itemsProduct() {
            let products = this.product || [];
            let objects = [];
            const count = products.length >= 10 ? 10 : products.length;
            for (let i = 0; i < count; i++) {
                const product = products[i];
                objects.push({
                    id: i + 1,
                    productName: product.productName || "",
                    quantity: product.quantity || 0,
                    total: util.convertCurrency(product.total || 0),
                });
            }
            return objects;
        },
        categoryItems() {
            const categories = this.categories || [];
            let objects = [];
            const count = categories.length >= 10 ? 10 : categories.length;
            for (let i = 0; i < count; i++) {
                const category = categories[i];
                objects.push({
                    id: i + 1,
                    categoryName: category.categoryName || "",
                    quantity: category.quantity || 0,
                    total: util.convertCurrency(category.total || 0),
                });
            }
            return objects;
        },
        fieldsProduct() {
            return [
                {
                    key: "id",
                    label: this.$i18n.t("number"),
                    _style: "width:5%",
                    _classes: "text-dark font-weight-normal",
                },
                {
                    key: "productName",
                    label: this.$i18n.t("productName"),
                    _style: "width:40%",
                    _classes: "text-dark font-weight-normal",
                },
                {
                    key: "quantity",
                    label: this.$i18n.t("quantity"),
                    _classes: "text-right font-weight-normal text-dark",
                },
                {
                    key: "total",
                    label: this.$i18n.t("sales"),
                    _classes: "text-right font-weight-normal text-dark",
                },
            ];
        },
        deliveryChart() {
            
            return {
                
                chartLabels: this.chartLabelsSummary ,
                datasets: [
                    {
                        label: this.$i18n.t("orderTotal"),
                        backgroundColor: '#00737c',
                        data: this.totalBillSummary.map(({ bills }) => bills), // Inner ring values
                    },
                ],
                chartOptions: {
                    responsive: true,
                    height: 200,
                    maintainAspectRatio: false,
                    cutoutPercentage: 10, // Doughnut hole size
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                        y: {
                            grid: {
                                display: true,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: true, // Hide default legend
                        },
                        tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    const day = tooltipItem.label; // Day name
                                    const dataType = tooltipItem.dataset.label; // "Delivery" or "Pickup"
                                    const value = tooltipItem.raw; // Raw data for the day
                                    return [
                                        `${day} - ${dataType}:`,
                                        `Total Bills: ${value.bills}`,
                                        `Grand Total: $${value.totalGrandTotal}`,
                                    ].join("\n");
                                },
                            },
                        },
                    },
                },
            };
        },
        deliverySuccessChart() {
            
            return {
                
                chartLabels: this.chartLabelsSummary ,
                datasets: [
                    {
                        label: this.$i18n.t("deliveryOnDemand"),
                        backgroundColor: '#00887f',
                        data: this.deliveryDataSummary.map(({ bills }) => bills), // Inner ring values
                    },
                    {
                        label: this.$i18n.t("selfPickUpOnDemand"),
                        backgroundColor: "#cfd06c",
                        data: this.pickupDataSumary.map(({ bills }) => bills), // Outer ring values
                    },
                ],
                chartOptions: {
                    responsive: true,
                    height: 200,
                    maintainAspectRatio: false,
                    cutoutPercentage: 10, // Doughnut hole size
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                        y: {
                            grid: {
                                display: true,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: true, // Hide default legend
                        },
                        tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    const day = tooltipItem.label; // Day name
                                    const dataType = tooltipItem.dataset.label; // "Delivery" or "Pickup"
                                    const value = tooltipItem.raw; // Raw data for the day
                                    return [
                                        `${day} - ${dataType}:`,
                                        `Total Bills: ${value.bills}`,
                                        `Grand Total: $${value.totalGrandTotal}`,
                                    ].join("\n");
                                },
                            },
                        },
                    },
                },
            };
        },
        deliveryChartCancel(){
            return {
                
                chartLabels: this.cancelChartLabelsSummary ,
                datasets: [
                    {
                        label: this.$i18n.t("reject"),
                        backgroundColor: '#079d76', 
                        data: this.rejectDataSummary.map(({ bills }) => bills), // Inner ring values
                    },
                    {
                        label: this.$i18n.t("cancel"),
                        backgroundColor: "#ffdd82",
                        data: this.cancelDataSummary.map(({ bills }) => bills), // Outer ring values
                    },
                ],
                chartOptions: {
                    responsive: true,
                    height: 200,
                    maintainAspectRatio: false,
                    cutoutPercentage: 10, // Doughnut hole size
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                        y: {
                            grid: {
                                display: true,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false, // Hide default legend
                        },
                        tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return `${tooltipItem.label}: ${tooltipItem}`;
                                },
                            },
                        },
                    },
                },
            };
        },
        isPermission() {
            // return permis.findPermissionRead('report', this.$route.path)
            return true
        },
        uid() {
            return `${localStorage.getItem('shopsUid')}`
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
            set(newValue) {
                return this.$store.dispatch('setShop', newValue)
            },
        },
        items() {
            const data = this.data;
            let info = [];
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                const paymentObjectId = item.payment?.objectId ?? "-";
                const member = item.member ?? null;
                const sumdiscount = (item.discountAmount || 0) + (item.discountItem || 0);
                const discount = util.convertCurrency(sumdiscount);
                const netAmount = util.convertCurrency(item.netAmount || 0);
                const grandTotal = util.convertCurrency(item.grandTotal || 0);
                const receiptItems = item.receiptItems || [];

                const netTotalIncVAT = util.convertCurrency(item.netTotalIncVAT || 0);
                const netTotalNonVAT = util.convertCurrency(item.netTotalNonVAT || 0);
                const netAmountVATTotal = util.convertCurrency(item.netAmountVATTotal || 0);
                const vatAmount = util.convertCurrency(item.vatAmount || 0);
                const attach = item.attach || [];
                const deliveryFee = util.convertCurrency(item.order?.shippingCost || 0)
                const shipping = item.order?.shippingCarrier || '-'
                const source = item.source || item.order?.shippingCarrier || '-'
                // parse order
                const order = item.order || {};
                const orderNumber = order.orderNumber || "";
                const gpTotal = util.convertCurrency(item.gpTotal || 0);
                const gpPercent = util.convertCurrency(item.gpPercent || 0);
                const  itemCount = receiptItems.length || 0;


                let typeOfOrder = ""
                if(item.typeOfOrder === 0){
                    typeOfOrder = 'N/A'
                }else if(item.typeOfOrder === 1){
                    typeOfOrder = this.$i18n.t("dineIn")
                }else if(item.typeOfOrder === 2){
                    typeOfOrder = this.$i18n.t("takeAway")
                }else if(item.typeOfOrder === 3){
                    typeOfOrder =this.$i18n.t("selfPickUp")
                }else if(item.typeOfOrder === 4){
                    typeOfOrder = this.$i18n.t("delivery")
                }else {
                    typeOfOrder = 'N/A'
                }

                let orderedAt = "";
                if (order.ordered_at != undefined) {
                    orderedAt = moment(String(order.ordered_at)).format("DD MMMM YYYY H:mm:ss");
                }

                let acceptedAt = "";
                if (order.accepted_at != undefined) {
                    acceptedAt = moment(String(order.accepted_at)).format("DD MMMM YYYY H:mm:ss");
                }

                let dueDate = "";
                if (order.dueDate != undefined) {
                    dueDate = moment(String(order.dueDate)).format("DD MMMM YYYY H:mm:ss");
                }

                let shippedAt = "";
                let status = "";
                if (order.shipped_at != undefined) {
                    shippedAt = moment(String(order.shipped_at)).format("DD MMMM YYYY H:mm:ss");
                    status = this.$i18n.t("sentSuccessfully");
                }

                // Set Style
                let classess = "text-dark";
                let statusshow = this.$i18n.t("success");
                if (item.receiptStatus === "V") {
                    statusshow = this.$i18n.t("cancel");
                    classess = "text-danger ";
                }

                const serviceCharge = util.convertCurrency(item.serviceCharge || 0);
                const roundingValue = util.convertCurrency(item.roundingValue || 0);
                const payReceiptAt = moment(String(item.payReceiptAt))
                    .tz("Asia/Bangkok")
                    .format("DD/MM/YYYY HH:mm:ss");

                info.push({
                    paymentObjectId: paymentObjectId,
                    dateTime: payReceiptAt,
                    receiptNumber: item.receiptNumber || "",
                    netAmount: netAmount,
                    discount: discount,
                    grandTotal: grandTotal,
                    receiptItems: receiptItems,
                    netTotalIncVAT: netTotalIncVAT,
                    netTotalNonVAT: netTotalNonVAT,
                    netAmountVATTotal: netAmountVATTotal,
                    vatAmount: vatAmount,
                    orderNumber: orderNumber,
                    orderedAt: orderedAt,
                    shippedAt: shippedAt,
                    status: status,
                    acceptedAt: acceptedAt,
                    dueDate: dueDate,
                    receiptObjectId: item.objectId,
                    roundingValue: roundingValue,
                    serviceCharge: serviceCharge,
                    receiptStatus: statusshow,
                    _classes: classess,
                    attach: attach,
                    member: member,
                    deliveryFee: deliveryFee,
                    shipping: shipping,
                    source: source,
                    typeOfOrder: typeOfOrder,
                    gpTotal : gpTotal,
                    gpPercent: gpPercent,
                    itemCount: itemCount
                });
            }
            return info;
        },
        fieldsDetail() {
            return [
                {
                    key: "receiptNumber",
                    label: this.$i18n.t("receiptNumber"),
                    _classes: "font-weight-normal text-dark",
                },
                {
                    key: "pluCode",
                    label: this.$i18n.t("pluCode"),
                    _classes: "font-weight-normal text-dark",
                },
                {
                    key: "pluName",
                    label: this.$i18n.t("productName"),
                    _classes: "font-weight-normal text-dark",
                },
                {
                    key: "quantitySKU",
                    label: this.$i18n.t("quantity"),
                    _classes: "text-center font-weight-normal text-dark",
                },
                {
                    key: "price",
                    label: this.$i18n.t("priceUnit"),
                    _classes: "text-right font-weight-normal text-dark",
                },
                {
                    key: "discountTotal",
                    label: this.$i18n.t("itemDiscount"),
                    _classes: "text-right font-weight-normal text-dark",
                },
                {
                    key: "totalAmount",
                    label: this.$i18n.t("netAmount"),
                    _classes: "text-right font-weight-normal text-dark",
                },
                {
                    key: "netAmount",
                    label: this.$i18n.t("totalAmount"),
                    _classes: "text-right font-weight-normal text-dark",
                },
            ];
        },
        fieldsPayment() {
            return [
                {
                    key: "paymentType",
                    label: this.$i18n.t("paymentType"),
                    _classes: "text-center text-dark",
                },
                {
                    key: "paid",
                    label: this.$i18n.t("paid"),
                    _classes: "text-right text-dark",
                },
                {
                    key: "change",
                    label: this.$i18n.t("change"),
                    _classes: "text-right text-dark",
                },
            ];
        },
        grabDonutCharts() {
            const success = this.billSuccess || 0;
            const cancel = this.billCancel || 0;
            const hasData = success > 0 || cancel > 0;

            return {
                // Data for the doughnut chart
                chartData: {
                    labels: hasData
                    ? [this.$i18n.t("success"), this.$i18n.t("cancel")]
                    : [this.$i18n.t("noData") || "No Data"], // Default label
                        datasets: [
                            {
                                data: hasData ? [success, cancel] : [1], // Default single slice
                                backgroundColor: hasData
                                    ? ["#51af65", "#cfd06c"] // Success and Cancel color s#00a766
                                    : ["#ebedef"], // Neutral color for no data
                                hoverBackgroundColor: hasData
                                    ? ["#00a766", "#8e9800"] // Hover colors for success and cancel
                                    : ["#d0d3d4"], // Neutral hover color
                                borderWidth: 1,
                            },
                    ],
                },
                // Chart options
                chartOptions: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    if (!hasData) return this.$i18n.t("noData") || "No Data";
                                    const dataset = tooltipItem.dataset?.data || [];
                                    const value = dataset[tooltipItem.dataIndex] || 0;
                                    const total = dataset.reduce((sum, val) => sum + val, 0) || 1;
                                    const percentage = ((value / total) * 100).toFixed(2);
                                    const label = tooltipItem.label || "Unknown";
                                    return `${label}: ${value} (${percentage}%)`;
                                },
                            },
                        },
                        legend: {
                            position: "top", // Position of the legend
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                },
            };
        },
        fieldsCategories() {
            return [
                {
                    key: "id",
                    label: this.$i18n.t("number"),
                    _style: "width:5%",
                    _classes: "text-dark font-weight-normal",
                },
                {
                    key: "categoryName",
                    label: this.$i18n.t("categoryName"),
                    _style: "width:40%",
                    _classes: "text-dark font-weight-normal",
                },
                {
                    key: "quantity",
                    label: this.$i18n.t("quantity"),
                    _classes: "text-right font-weight-normal text-dark",
                },
                {
                    key: "total",
                    label: this.$i18n.t("sales"),
                    _classes: "text-right font-weight-normal text-dark",
                },
            ];
        },
        fields() {
            return [
               
                {
                    key: "dateTime",
                    label: this.$i18n.t("date"),
                    _classes: "text-center text-dark font-weight-normal",
                    _style: "width:10%",
                },
                {
                    key: "receiptNumber",
                    label: this.$i18n.t("receiptNumber"),
                    _classes: "text-center text-dark font-weight-normal",
                    _style: "width:10%",
                },
                {
                    key: "orderNumber",
                    label: this.$i18n.t("orderNo"),
                    _classes: "text-center text-dark font-weight-normal",
                    _style: "width:10%",
                },
                {
                    key: "typeOfOrder",
                    label: this.$i18n.t("orderType"),
                    _classes: "text-right text-dark font-weight-normal",
                    _style: "width:10%",
                },
                
                {
                    key: "gpPercent",
                    label: this.$i18n.t("GP (%)"),
                    _classes: "text-right text-dark font-weight-normal",
                    _style: "width:15%",
                },
                {
                    key: "gpTotal",
                    label: this.$i18n.t("afterGP"),
                    _classes: "text-right text-dark font-weight-normal",
                    _style: "width:15%",
                },
                {
                    key: "grandTotal",
                    label: this.$i18n.t("grandTotal"),
                    _classes: "text-right text-dark font-weight-normal",
                    _style: "width:10%",
                },
                {
                    key: "itemCount",
                    //   label: this.$i18n.t("txtSalesChannels"),
                    label: this.$i18n.t("itemCount"),
                    _classes: "text-left text-dark font-weight-normal ",
                    _style: "width:10%",
                },
                {
                    key: "receiptStatus",
                    //   label: this.$i18n.t("txtSalesChannels"),
                    label:this.$i18n.t('status'),
                    _classes: "text-left text-dark font-weight-normal ",
                    _style: "width:10%",
                },
            ];
        },
        totalBillSummary(){

            const cancel = this.summaryOrderCancelData
            const success = this.totalSuccessBills
            
            const summary = cancel.map((cancelItem, index) => {
            const successItem = success[index];
            return {
                bills: cancelItem.bills + successItem.bills,
                totalGrandTotal: cancelItem.totalGrandTotal + successItem.totalGrandTotal,
            };
            });

            return summary
        },
        chartOptions() {
      return {
        tooltip: {
          position: "top",
          formatter: (params) => {
            if (!params.data) return "No data";
            const days = [
              this.$i18n.t("sunday"), this.$i18n.t("monday"), this.$i18n.t("tuesday"),
              this.$i18n.t("wednesday"), this.$i18n.t("thursday"), this.$i18n.t("friday"),
              this.$i18n.t("saturday"),
            ];
            const dayName = days[params.data[1]];
            return `
              <strong>Day:</strong> ${dayName} <br>
              <strong>Hour:</strong> ${params.data[0]} <br>
              <strong>Bills Total:</strong> ${params.data[2]} <br>
            `;
          },
        },
        data: this.datalist,
        grid: {
          height: "80%" ,
          top: "5%",
          left: "0%",
          width: "100%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [
            "12 A.M", "1 A.M", "2 A.M", "3 A.M", "4 A.M", "5 A.M", "6 A.M", "7 A.M",
            "8 A.M", "9 A.M", "10 A.M", "11 A.M", "12 P.M", "1 P.M", "2 P.M", "3 P.M", 
            "4 P.M", "5 P.M", "6 P.M", "7 P.M", "8 P.M", "9 P.M", "10 P.M", "11 P.M",
          ],
          splitArea: {
            show: true,
          },
        },
        yAxis: {
          type: "category",
          data: [
            this.$i18n.t("sunday"), this.$i18n.t("monday"), this.$i18n.t("tuesday"),
            this.$i18n.t("wednesday"), this.$i18n.t("thursday"), this.$i18n.t("friday"),
            this.$i18n.t("saturday"),
          ],
          splitArea: {
            show: true,
          },
        },
        visualMap: {
          min: 0,
          max: this.maxBill || 1,
          calculable: true,
          orient: "horizontal",
          left: "center",
          top: "85%",
          bottom: "0%",
          inRange: {
            color: ["#FFFFFF", "#cfd06c", "#94c063", "#51af65"],
          },
        },
        series: [
          {
            name: "Hourly Sales Report",
            type: "heatmap",
            data: this.datalist,
            label: {
              show: true,
              fontSize: 12,
              color: "#000",
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 20,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    },
    created() {
        this.getOrderShopSetting()
        this.getStaticAmount()
        // this.$refs.getHourly.getHourReport() 
        this.getOrderSummary()
        this.getGrabTransaction()
        this.getCancelOrderSummary()
        this.getProductBestSeller()
        this.getCategoryBestSeller()
        this.getCancelReason()
        this.getHourReport()
    },
    methods: {
        ...util,
        getCancelReason(){
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
            const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

            let params = {
                shopObjectId: shopObjectId,
                startAt: startAt,
                endAt : endAt,
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            pos({
                url: "/api/v3.0/sellingchannel/statistic/cancelreason",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {
                
                this.cancelReason =  res.data.data
         
            });
        },
        getBackgroundColor(index) {
            const hexColor = this.getChartColor(index);
            const rgbaColor = this.hexToRgba(hexColor, 0.03); // 10% opacity
            return rgbaColor;
        },
        getCancelBackgroundColor(index) {
            const hexColor = this.getCancelChartColor(index);
            const rgbaColor = this.hexToRgba(hexColor, 0.03); // 10% opacity
            return rgbaColor;
        },
        getCategoryBackgroundColor(index){
            const hexColor = this.getChartCategoryColor(index);
            const rgbaColor = this.hexToRgba(hexColor, 0.03); // 10% opacity
            return rgbaColor;
        },
        hexToRgba(hex, alpha) {
            // Convert HEX color to RGBA
            let c;
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split('');
                if (c.length === 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = '0x' + c.join('');
                return `rgba(${(c >> 16) & 255}, ${(c >> 8) & 255}, ${c & 255}, ${alpha})`;
            }
            return hex; // Return original if invalid HEX
        },
        getChartColor(index) {
            const colors = this.topProducts.datasets[0].backgroundColor || ["#ebedef"]; // Fallback color
            return colors[index % colors.length];
        },
        getCancelChartColor(index){
            const colors = this.cancelreasonList.datasets[0].backgroundColor || ["#ebedef"]; // Fallback color
            return colors[index % colors.length];
        },
        getChartCategoryColor(index) {
            const colors = this.topCategories.datasets[0].backgroundColor || ["#ebedef"]; // Fallback color
            return colors[index % colors.length];
        },
        getButtonClass(key) {
            if(this.filterVal === key){
                // if(this.sortOrder === order){
                //     return 'btn btn-active'
                // }else{
                //     return 'btn btn-inactive'
                // }
                return 'btn btn-active'
            }else{
                return 'btn btn-inactive'
            }
        },
        getArrowClass(key) {
            if (this.filterVal === key) {
                return key === 'desc' ? 'fi fi-rr-arrow-small-down' : 'fi fi-rr-arrow-small-up';
            }
            return '';
        
        },
        toggleSort(key) {
            this.filterVal = key
            // this.sortOrder = order

            this.getGrabTransaction();  // Send the API request only when the button is active
        },
        getOrderShopSetting(){
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`
            let params = {
                shopObjectId: shopObjectId,
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            pos({
                url: "/api/v3.0/shop/ordershopsetting",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {
                if(res.status ==200){
                    let isGrabOrderEnabled  = res.data.data.isGrabOrderEnabled
                    if(isGrabOrderEnabled!== undefined && isGrabOrderEnabled !== null && isGrabOrderEnabled!==''){
                        this.isGrabOrderEnabled = isGrabOrderEnabled
                    }else{
                        this.isGrabOrderEnabled = false
                    }
                }else{
                    this.isGrabOrderEnabled = false
                }
                
            }).catch((error) => {
                    console.log(error);
            });
        },
        getProductBestSeller(){
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
            const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

            let params = {
                shopObjectId: shopObjectId,
                startAt: startAt,
                endAt : endAt,
                channel : '101',
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            pos({
                url: "/api/v3.0/sellingchannel/statistic/bestseller/product",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {
                this.productBestSeller =  res.data.data
         
            });
        },
        getCategoryBestSeller(){
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
            const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

            let params = {
                shopObjectId: shopObjectId,
                startAt: startAt,
                endAt : endAt,
                channel : '101',
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            pos({
                url: "/api/v3.0/sellingchannel/statistic/bestseller/category",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {
                this.categoryBestSeller =  res.data.data
               
            });
        },
        getCancelOrderSummary(){
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
            const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

            let params = {
                shopObjectId: shopObjectId,
                startAt: startAt,
                endAt : endAt,
                channel : '101',
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            pos({
                url: "/api/v3.0/sellingchannel/statistic/cancel",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {

                const dayNames = [this.$i18n.t("sun"), this.$i18n.t("mon"), this.$i18n.t("tues"), this.$i18n.t("wed"), this.$i18n.t("thur"), this.$i18n.t("fri"),this.$i18n.t("sat")];
                const chartData = Array(7).fill(0).map((_, index) => ({
                    day: dayNames[index],
                    rejectData: { bills: 0, totalGrandTotal: 0 }, // Separate bills and grandTotal for delivery
                    cancelData: { bills: 0, totalGrandTotal: 0 },   
                    totalBill : {bills : 0,totalGrandTotal: 0}

                }));

                let data = res.data.data;

                data.forEach(({ dayOfWeek, orderStatus, bill, grandTotal }) => {
                        const dayIndex = parseInt(dayOfWeek, 10) - 1; 
                        if (orderStatus === 'REJECT') {
                            chartData[dayIndex].rejectData.bills += bill;
                            chartData[dayIndex].rejectData.totalGrandTotal += grandTotal;
                        } else if (orderStatus === 'CANCEL') {
                            chartData[dayIndex].cancelData.bills += bill;
                            chartData[dayIndex].cancelData.totalGrandTotal += grandTotal;
                        }
                        chartData[dayIndex].totalBill.bills += bill;
                        chartData[dayIndex].totalBill.totalGrandTotal += grandTotal;
                });

                const chartLabels = chartData.map(({ day }) => day);
                const rejectData = chartData.map(({ rejectData }) => rejectData);
                const cancelData = chartData.map(({ cancelData }) => cancelData);
                const sumCancelData = chartData.map(({ totalBill }) => totalBill);

                this.cancelChartLabelsSummary = chartLabels;                
                this.rejectDataSummary = rejectData; // Contains structured data for delivery
                this.cancelDataSummary = cancelData;     
                this.summaryOrderCancelData = sumCancelData
        
            });
        },
        showStatusSummary(status){
            this.isShowStatusSuccess  = status
        },
        getGrabReport(){
            this.getStaticAmount()
            this.getOrderSummary()
            this.getGrabTransaction()
            this.getCancelOrderSummary()
            this.getProductBestSeller()
            this.getCategoryBestSeller()
            this.getCancelReason()
            this.getHourReport()
        },
        getOrderSummary(){
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
            const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

            let params = {
                shopObjectId: shopObjectId,
                startAt: startAt,
                endAt : endAt,
                channel : '101',
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            pos({
                url: "/api/v3.0/sellingchannel/statistic/ordertype",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {
                const dayNames = [this.$i18n.t("sun"), this.$i18n.t("mon"), this.$i18n.t("tues"), this.$i18n.t("wed"), this.$i18n.t("thur"), this.$i18n.t("fri"),this.$i18n.t("sat")];
                const chartData = Array(7).fill(0).map((_, index) => ({
                    day: dayNames[index],
                    delivery: { bills: 0, totalGrandTotal: 0 }, // Separate bills and grandTotal for delivery
                    pickup: { bills: 0, totalGrandTotal: 0 },   
                    totalBill : {bills : 0,totalGrandTotal: 0}
                }));

                let data = res.data.data;

                data.forEach(({ dayOfWeek, typeOfOrder, bill, grandTotal }) => {
                        const dayIndex = parseInt(dayOfWeek, 10) - 1; 
                        if (typeOfOrder === 4) {
                            chartData[dayIndex].delivery.bills += bill;
                            chartData[dayIndex].delivery.totalGrandTotal += grandTotal;
                        } else if (typeOfOrder === 3) {
                            chartData[dayIndex].pickup.bills += bill;
                            chartData[dayIndex].pickup.totalGrandTotal += grandTotal;
                        }
                        chartData[dayIndex].totalBill.bills += bill;
                        chartData[dayIndex].totalBill.totalGrandTotal += grandTotal;

                });

                const chartLabels = chartData.map(({ day }) => day);
                const deliveryData = chartData.map(({ delivery }) => delivery);
                const pickupData = chartData.map(({ pickup }) => pickup);
                const totalBill = chartData.map(({ totalBill }) => totalBill);


                this.chartLabelsSummary = chartLabels;
                this.deliveryDataSummary = deliveryData; // Contains structured data for delivery
                this.totalSuccessBills = totalBill
                this.pickupDataSumary = pickupData;     
        
            });
        },
        getStaticAmount(){
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
            const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

            let params = {
                shopObjectId: shopObjectId,
                startAt: startAt,
                endAt : endAt,
                channel : '101',
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            pos({
                url: "/api/v3.0/sellingchannel/statistic/amount",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {
                let data = res.data.data;
                let billTotal = 0
                let totalRevenue = 0
                for (let i = 0; i < data.length; i++) {
                    if(data[i].receiptStatus === 'V'){
                        this.billCancel = data[i].bill
                        this.totalAmountCancel =  util.convertCurrency(data[i].grandTotal)    
                    }

                    if(data[i].receiptStatus === 'A'){
                        this.billSuccess = data[i].bill
                        this.totalAmountSuccess = util.convertCurrency(data[i].grandTotal)
                    }  

                    totalRevenue = totalRevenue + data[i].grandTotal
                    billTotal = billTotal + data[i].bill
                }

                this.totalRevenue = util.convertCurrency(totalRevenue)
                this.billTotal = billTotal
                this.dataAmount = data
            });
        },
        toggleDetails(item, index) {
            const position = this.details.indexOf(item);
            this.order(index);
            position !== -1
                ? this.details.splice(position, 1)
                : this.paymentDetail(item.paymentObjectId, item);
        },
        rowClicked(item) {
            this.$router.push(
                '/report/daily/' + this.shopObjectId + '/' + item.dateString
            )
        },
        order(index) {
            let data = this.data[index].order;
            let detail = {};

            if (data != undefined || data != null) {
                detail = {
                    orderNumber: data.orderNumber,
                    orderedAt: moment(data.ordered_at).format("DD MMMM YYYY H:mm:ss"),
                    status: this.$i18n.t("sentSuccessfully"),
                    acceptedAt: moment(data.accepted_at).format("DD MMMM YYYY H:mm:ss"),
                    dueDate: moment(data.dueDate).format("DD MMMM YYYY H:mm:ss"),
                    shippedAt: moment(data.shipped_at).format("DD MMMM YYYY H:mm:ss"),
                };
            }
            this.orderDetail = detail;
        },
        itemsDetail(index) {
            let data = this.data[index].receiptItems;
            let info = [];
            for (let i = 0; i < data.length; i++) {
                let discountBillPerQty = 0;
                let total = 0;
                let discountAmount = 0;

                if (data[i].deleted_at == null) {
                    if (data[i].discountBillPerQty !== null) {
                        discountBillPerQty = data[i].discountBillPerQty * data[i].quantity;
                    }

                    if (data[i].discountAmount !== null) {
                        discountAmount = data[i].discountAmount;
                    }
                    total = data[i].netAmount - discountBillPerQty;
                    let discountTotal = discountAmount + discountBillPerQty;

                    info.push({
                        receiptNumber: this.data[index].receiptNumber,
                        pluCode: data[i].pluCode,
                        pluName: data[i].pluName,
                        orderedOptions: data[i].orderedOptions,
                        quantitySKU: data[i].quantitySKU,
                        price: util.convertCurrency(data[i].price),
                        discountTotal: util.convertCurrency(discountTotal),
                        totalAmount: util.convertCurrency(data[i].totalAmount),
                        netAmount: util.convertCurrency(total),
                    });
                }
            }
            return info;
        },
        paymentDetail(objectId, item) {
            if (objectId === "-") {
                Object.assign(item, { paymentdataDtail: "-" });
                Object.assign(item, { paymentdata: "-" });
                Object.assign(item, { paymentchange: "-" });
                this.details.push(item);
                return;
            }
            const uid = `${localStorage.getItem("shopsUid")}`;
            const params = { objectId: objectId };
            const headers = { shopObjectId: this.shopObjectId };

            axios({
                url: "/collection/v1.0/Payment/" + uid,
                params: params,
                headers: headers,
                method: "GET",
            })
                .then((res) => {
                    let data = res.data.data;
                    let paymentItems = res.data.data.paymentItems;
                    let paymentType = "";
                    let detail = [];
                    let info = [];

                    for (let i = 0; i < paymentItems.length; i++) {
                        switch (paymentItems[i].paymentType) {
                            case 0:
                                paymentType = this.$i18n.t("paymentType0");
                                break;
                            case 1:
                                paymentType = this.$i18n.t("paymentType1");
                                break;
                            case 2:
                                paymentType = this.$i18n.t("paymentType2");
                                break;
                            case 3:
                                paymentType = this.$i18n.t("paymentType3");
                                break;
                            case 4:
                                paymentType = this.$i18n.t("paymentType4");
                                break;
                            case 5:
                                paymentType = this.$i18n.t("paymentType5");
                                break;
                            case 6:
                                paymentType = this.$i18n.t("paymentType6");
                                break;
                            case 7:
                                paymentType = this.$i18n.t("paymentType7");
                                break;
                            case 8:
                                paymentType = this.$i18n.t("paymentType8");
                                break;
                            case 9:
                                paymentType = this.$i18n.t("paymentType9");
                                break;
                            case 10:
                                paymentType = this.$i18n.t("paymentType10");
                                break;
                            case 12:
                                paymentType = this.$i18n.t("paymentType12");
                                break;
                            case 14:
                                paymentType = this.$i18n.t("paymentType14");
                                break;
                            default:
                                paymentType = "-";
                        }

                        detail.push({
                            paymentType: paymentType,
                            inputAmount: util.convertCurrency(paymentItems[i].inputAmount),
                            paid: util.convertCurrency(data.paid),
                            change: util.convertCurrency(data.change),
                        });
                    }

                    if (detail.length > 0) {
                        info = {
                            detail: detail,
                            paid: detail[0].paid,
                            change: detail[0].change,
                        };
                    } else {
                        info = {
                            detail: detail,
                            paid: 0,
                            change: 0,
                        };
                    }

                    Object.assign(item, { paymentdataDtail: detail });
                    Object.assign(item, { paymentdata: info });
                    Object.assign(item, { paymentchange: info.change });
                    this.details.push(item);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getGrabTransaction(page = 1) {
            
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
            const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

            const headers = {
                shopObjectId: shopObjectId,
            }
            let params = {}
            if(this.filterVal != ''){
                params = {
                    startAt: startAt,
                    endAt: endAt,
                    page: page,
                    channel : '101',
                    // sortAttr : this.sortAttr,
                    // sortOrder : this.sortOrder,
                    limit: 10,
                    filterAttr : 'receiptStatus',
                    filterVal : this.filterVal
                }
            }else{
                 params = {
                    startAt: startAt,
                    endAt: endAt,
                    page: page,
                    channel : '101',
                    // sortAttr : this.sortAttr,
                    // sortOrder : this.sortOrder,
                    limit: 10,   
                }
            }
            // params = {
            //         startAt: startAt,
            //         endAt: endAt,
            //         page: page,
            //         channel : '101',
            //         limit: 10,       
            //         filterAttr : 'receiptStatus',
            //         filterVal : this.filterVal      
            // }

            report({
                url: '/api/v3.0/receipt/sale/channel',
                params: params,
                headers : headers,
                method: "GET",
            })
                .then((res) => {
                    if (res != null) {
                        this.data = res.data.data;
                        if (res.data.paginate.items == undefined) {
                            this.meta_data.items = 0;
                        } else {
                            this.meta_data.items = res.data.paginate.items;
                        }
                        this.meta_data.last_page = res.data.paginate.pageCount;
                        this.meta_data.current_page = res.data.paginate.currentPage;
                        this.meta_data.itemCount = res.data.paginate.itemCount;
                        this.meta_data.limit = res.data.paginate.perPage;

                        this.loading = false;
                        this.loadingButton = true;
                    } else {
                        this.loading = false;
                        this.loadingButton = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getHourReport() {
      const shopObjectId = localStorage.getItem("shopObjectId");
      const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
      const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

      const params = {
        shopObjectId,
        startAt,
        endAt,
        channel: "101",
      };
      const headers = {
        shopObjectId,
      };

      pos({
        url: "/api/v3.0/sellingchannel/statistic/orderfrequency",
        params,
        headers,
        method: "get",
      })
        .then((res) => {
          const rawData = res.data.data || [];
          if (!rawData.length) {
            this.datalist = [];
            this.maxBill = 1;
            return;
          }

          const daysMap = { "1": 0, "2": 1, "3": 2, "4": 3, "5": 4, "6": 5, "7": 6 };
          const aggregatedData = rawData.reduce((acc, curr) => {
            const key = `${curr.dayOfWeek}-${curr.hourOfDay}`;
              if (!acc[key]) acc[key] = { ...curr, bill: 0 };
              acc[key].bill += curr.bill;
              return acc;
            }, {});

          this.datalist = Object.values(aggregatedData).map((item) => [
            parseInt(item.hourOfDay, 10),
            daysMap[item.dayOfWeek],
            item.bill,
          ]);

          this.maxBill = Math.max(...Object.values(aggregatedData).map((item) => item.bill));
              
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    },
}
</script>
<style>
.delivery-chart {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}

.legend {
    width: 250px;
}

.legend ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.legend li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.color-box {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    display: inline-block;
}

.sorting-container {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #dfe1e5;
}

.sorting-label {
  margin-right: 10px;
  color: #47525e;
  font-weight: 500;
}

.sorting-buttons {
  display: flex;
  gap: 10px; /* Increased gap for more spacing */
}
.btn-inactive {
  background-color: white;
  color: #47525e;
}

.btn-inactive:hover {
  background-color: #e9ecef;
}

.btn-active {
  background-color: #3abc98;
  color: white;
}
.bg-cancel {
    background-color: #F9F1F0 !important;
    transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.bg-cancel:hover {
    background-color: #fbe7e4 !important; /* Slightly darker shade on hover */
    cursor: pointer; /* Optional: Add pointer cursor for better UX */
}
.heatmap-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.heatmap-chart {
  width: 100%;
  height: 380px;
}

 /* @media (max-width: 768px) {
  .heatmap-container {
    padding: 10px;
  }


}

@media (min-width: 1200px) {
  .heatmap-chart {
    height: 380px;
  }
}  */
.access-con{
    position: relative;
    width: 100%;
}
.access-con .action-grab{
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* background-color: #555;
  color: white; */
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
</style> 